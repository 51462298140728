<template>
    <front-layout>
        <main>
            <section class="intro">
                <div class="wrapper">
                    <p class="intro__subtitle text-style">Page not found</p>
                </div>
            </section>
        </main>
    </front-layout>
</template>

<script>
import FrontLayout from "../../layouts/FrontLayout";
export default {
    components: {FrontLayout},
    data: function () {
        return {}
    },
}
</script>

<style scoped>

</style>
